
import Web3 from "web3";

//地址字符串处理
function adrTostr(_text){
    let text =String(_text);
    let length = text.length;
    let startstr = text.substring(0,4);
    let endstr = text.substring(length-5,length);
    let str =  startstr +"..." + endstr;
    return str;
}

function adrTostr2(_text){
    let text =String(_text);
    let length = text.length;
    let startstr = text.substring(0,10);
    let endstr = text.substring(length-10,length);
    let str =  startstr +"..." + endstr;
    return str;
}


function toWei(num){
    const web3 = new Web3();
    return web3.utils.toWei(String(num),'ether')
}

function fromWei(num){
    const web3 = new Web3();

    let str  = web3.utils.fromWei(String(num),'ether');

    let str2 = str * 10**6;

    return  Math.floor(str2) / 10**6;
}

function fromWeiw(num){
    const web3 = new Web3();

    let str  = web3.utils.fromWei(String(num),'ether');

    let str2 = str /10000;

    return  Math.ceil(str2);
}


//判断地址是否有效
function isAddress(adr){
    const web3 = new Web3();
    return web3.utils.isAddress(adr);
}

//fenye 
function pageaction(page,pageSize,total){
    let state = (page-1) * pageSize;   //开始位置
    let end = state + pageSize >=total ? total :  state + pageSize;
    let pageTotal =Math.ceil(total/pageSize);

    return{
        state,
        end,
        pageTotal
    } 
}

function copyFn(val) {
    // createElement() 方法通过指定名称创建一个元素
    var copyInput = document.createElement("input");
    //val是要复制的内容
    copyInput.setAttribute("value", val);
    document.body.appendChild(copyInput);
    copyInput.select();
    try {
      var copyed = document.execCommand("copy");
      if (copyed) {
        document.body.removeChild(copyInput);
        // this.$Message.success("复制成功");
        console.log("复制成功")
        return 1
      }
    } catch {
        return 0
    }
}


export const getBit = (value,bit=5)=>{


    if (value) {
        let str = value.toString();
    
        let strIndex = str.indexOf('.');
        if (strIndex === -1) return str;
        str = str.substring(0, strIndex + bit);
        return str;
    } else {
        return 0
    }

  
   
}





export default{
    adrTostr,
    toWei,
    fromWei,
    isAddress,
    pageaction,
    copyFn,
    fromWeiw,
    adrTostr2
}