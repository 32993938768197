// api接口统一管理
import {
  vpost
} from './http.js'

//个人信息 
export const information = p => vpost('/api/information', p);

//绑定上级 
export const bind = p => vpost('/api/bind', p);

//团队信息 
export const team = p => vpost('/api/team', p);

//入金订单- 
export const deposit = p => vpost('/api/deposit', p);


//产市记录- 
export const produce = p => vpost('/api/produce', p);
