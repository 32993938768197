/* eslint-disable no-undef */
// 在http.js中引入axios
import axios from 'axios'
import store from '@/store'
import md5 from 'js-md5';
axios.defaults.baseURL = 'https://lr.xingqiao.cn/'; 

axios.defaults.headers.post['Content-Type'] = 'application/json'

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  // const token = passport.token
  const config = {
    params: params
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
      
        reject(err)
      })
  })
}
// axios.defaults.headers['lang'] = store.state.lang ||  "en";
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function vpost(url, params) {

  const time =  parseInt(new Date().getTime() / 1000) + '';
  const keys = "35fe01f8de09cd1ea9a4f0398ff745ed";
  const keys2 ="31c1573cdbbdd8a38b01fb5dc2f4325cf7e16c7de109c4b045856b12f67b9462";
  const account = store.state.account;
  const headers = {
    'Content-Type': 'application/json',
    'lang':store.state.lang ||  "en",
    'time':time,
    'keys':keys,
    "v": md5(time + keys2 + account)
    
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, JSON.stringify(params), { headers })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function post(url, params) {
  const headers = {
    'Content-Type': 'application/json',

    'lang':store.state.lang ||  "en"
    
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, JSON.stringify(params), { headers })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}



/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function upload(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        resolve(res)
      })
      .catch(err => {

        reject(err)
      })
  })
}