<template>
  <div ref="wrapper">

    <div>
        <slot></slot>
    </div>
     
  </div>
</template>

<script>
import { reactive, toRefs, ref,onMounted,nextTick,defineExpose } from 'vue'
import BScroll from 'better-scroll'
export default {
  name: 'BScroll',
  setup(props, {emit}) {
    const data = reactive({
        scroll:null
    });
    const wrapper = ref();

    nextTick(()=>{
           data.scroll = new BScroll(wrapper.value,{
           click:true,
           mouseWheel: true,
           probeType : 2,
           pullUpLoad:{   //上拉加载
             threshold:-100
           },
           pullDownRefresh:{   //下拉
             threshold:30
           }
       })
       data.scroll.on('pullingDown',()=>{
         data.scroll.finishPullDown();
       })
        data.scroll.on('pullingUp',()=>{
          emit('pullingUp');
          data.scroll.finishPullUp();

       })

    })

    const refresh = (()=>{
         data.scroll.finishPullUp();
         data.scroll.finishPullDown();
         data.scroll.refresh();

    })
    //滚动到最底部 
    const ScrollToEndFlag = ()=>{

      data.scroll.scrollTo(0, data.scroll.maxScrollY)
    }

    defineExpose({
      refresh
    })

    onMounted(() => {
     
    })
    

    return {
      ...toRefs(data),
      wrapper,
      refresh,
      ScrollToEndFlag
    }
  },

 
}
</script>
