<template>
      <header>
          <van-nav-bar :title="props.msg" 
            :border="false"
            :fixed="true"
            @click-left="leftUrl? $router.push(leftUrl) :this.$router.go(-1)"
            @click-right="()=>{connectWallet()}"
            :left-arrow="leftArrow"
         > 
            <template #right>
              <van-button class="accBtn" 
                color="#006CFF" 
                plain 
                hairline 
                size="small"
                round 
                type="primary"
                >{{strAccount}}</van-button>
            </template>
         </van-nav-bar>
     </header>
</template>

<script>
import { reactive, toRefs, onMounted,computed } from 'vue'
import UU from '@/utils/string'
import { Notify, Toast } from 'vant';
import {useStore} from 'vuex';
import { useI18n } from "vue-i18n";
import {useRouter} from 'vue-router'
import tp from "tp-js-sdk";
export default {
  name: 'Header',
  props:{
     msg:String,
     listUrl:String,
     rightText:String,
     leftUrl:String,
     leftArrow:{
      type:Boolean,
      default:true
     }
  },
  setup(props) {

    const $store = useStore();
  
    const data = reactive({})
    const {t}=  useI18n();
    const router  =useRouter();
    const connectWallet = ()=>{
          if(props.listUrl){
                router.push(props.listUrl);
                return
          }

          if (window.ethereum) {
            window.ethereum.enable().then((res) => {
              $store.commit('setAccount',res[0]);

              tp.getCurrentWallet().then(({data})=>{

                  if (data.walletType == "observeWallet") {
                    $store.commit('setWalletType',1);


                  }else{
                    $store.commit('setWalletType',0);
                  }
                })


            });
          } else {
             Notify({ type: 'primary',  message: t('tips.a1') });
          }
     };
     const strAccount = computed(()=>{   
         let { account } =  $store.state;
         return  account ? UU.adrTostr(account) :"Connect"
     })
    onMounted(() => {

      if ( props.listUrl ) {
        return
      } else {
        let { account } =  $store.state;
        account || connectWallet();    
      }
    
      
    })
    return {
      ...toRefs(data),
      props,
      strAccount,
      connectWallet
    
    }
  },
}

</script>
<style  scoped  lang='scss'>
 header{
   --van-nav-bar-background-color:#0D1D3C;
   --van-nav-bar-height:1rem;
  --van-nav-bar-title-text-color:#ffffff;
   --van-nav-bar-icon-color:#ffffff;
   
   .accBtn{
    height: .54rem;
    padding: 0 .2rem;
    background-color: #1A315B;
   }
 }

 

</style>